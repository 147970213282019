import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const TowerLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Houman"
    institution="Tower Urology"
    referralCode="TOWER"
    physicianURL="https://www.towerurology.com/our-physicians/justin-houman-md/"
  />
)

export default TowerLandingPage
